.custom-icon {
    cursor: pointer;
    margin: .25rem;
}

.md {
    font-size: 1.5rem;
}

.lg {
    font-size: 2rem;
}

.xl {
    font-size: 2.5rem;
}