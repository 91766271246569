@import "~bootstrap/scss/bootstrap";
@import "../../custom-varaibles";

.advisor-card {
    margin-top: 4rem;
    position: relative;
    border-radius: .5rem;
    transition: .2s all ease-out;

    &:hover {
        transform: scale(1.02);
        box-shadow: $box-shadow !important;
    }

    &--image {
        margin: -4rem 0 1rem 0;
        width: 6rem;
        height: 6rem;
        overflow: hidden;
        border-radius: 999px;
        border: .2rem solid $brand-2;
    }

    &--content {
        position: relative;
        z-index: 3;
        &::before {
            content: "";
            position: absolute;
            left: 1rem;
            top: -1rem;
            background-image: url('../../assets/quote-left.svg');
            background-size: contain;
            background-repeat: no-repeat;
            width: 3rem;
            height: 3rem;
            opacity: .2;
            z-index: 2;
        }
    }
}