@import "../../custom-varaibles";

.niis-junior-college-hero {
  background-image: linear-gradient(
      to right bottom,
      rgba($brand-white, .3),
      rgba($brand-white, .4),
    ),
    url("./../../assets/niis-hero-bg.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.hero-content {
  overflow: hidden;
}
