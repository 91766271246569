@import "~bootstrap/scss/bootstrap";
@import "../../custom-varaibles";

.course-card {
    transition: .2s all ease-out;
    &:hover {
        transform: scale(1.02);
        box-shadow: $box-shadow !important;
        border: 1px solid $brand-2;
    }
}