@import "../../custom-varaibles";

.division-card {
  transition: 0.3s all ease-out;
  transform: scale(1);

  & img {
      width: 124px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

    & h5 {
      color: $brand-1;
    }
  }
}
