.news-container {
  position: relative;
  width: 100%;
  overflow: hidden;

  &-mobile {
    height: 550px;
  }
}

.news-controls-vertical {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translate(2%, -50%);
  z-index: 2;
}

.news-controls-horizontal {
  position: absolute;
  width: 100%;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);

  & .control {
    margin: 0 0.25rem;
  }
}
