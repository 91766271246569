@import "../../custom-varaibles";

.college-name-mini-card {
	width: 100%;
	position: relative;
	background-color: $heading-color;

	&--image {
		opacity: 0.84;
		width: 100%;
		height: 100%;
	}

	&--title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;

		color: white;
		text-transform: uppercase;
		font-size: 1.25rem;
		width: 65%;
		margin: 0.5rem auto;
		padding: 0.5rem;
		background-color: $link-color;

		& * {
			text-align: center;
			font-family: $paragraph-font;
			font-weight: 500;
			margin-bottom: 0;
		}
	}
}
