@import "../../custom-varaibles";

.courses-mini-card {
  padding: 1rem;
  background-color: transparent;
  text-decoration: none;

  &:hover, &:active {
    text-decoration: none;
  }
  
  &--image {
    margin-bottom: 1rem;
  }

  &--name {
    font-weight: 700;
    color: $highlight-color;
    text-align: center;
    font-size: 1.25rem;
  }

  &--title {
    text-align: center;
    color: $text-color;
    font-size: 1rem;
    max-width: 200px;
    margin: 0 auto;
  }
}
