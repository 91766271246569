@import "../../custom-varaibles";

.testimonial-container {
  position: relative;

  & .testimonial-controls {
    position: absolute;
    width: 100%;
    top: 50%;
    z-index: 2;

    & .control {
      margin: 0 .25rem;
    }
  }
}
