@import "../../custom-varaibles";

.mini-card {
  max-width: 200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-out;
  transform: scale(1);
  width: 100%;
  border: none !important;
  outline: none;
  
  & img {
    width: 124px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;

    & h5 {
      color: $brand-1;
    }
  }
}
